import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Button from '@components/Button/Button';
import Card from '@components/Card/Card';
import styles from './Banner.module.scss';
import classNames from "classnames/bind";
import { API_ROOT } from '@config';

const Banner = () => {
	const history = useHistory();
	const content = useSelector((state) => state.staticData.content);
	const bannerImage =
		content['home-page']?.indexed['banner']['content_item_image'];
	const ctaImage = content['home-page']?.indexed['cta']['content_item_image'];
	// const ctaImage = content['home-page']?.indexed['cta']['content_item_image'];
	return (
		<div
			className={styles.container}
			style={{
				backgroundColor: 'white',
			}}>
			<div className={styles.content}>
				<div style={{ flex: 1 }} className={styles.leftImage}>
					<img src={`${API_ROOT}/uploaded/content/${bannerImage}`} />
				</div>
				<Card
					className={styles.card}
					title={
						<div
							dangerouslySetInnerHTML={{
								__html: content['home-page']?.indexed['cta'][
									'content_item_html'
								],
							}}
						/>
					}>
					<div className={styles.images}>
						<img src={`${API_ROOT}/uploaded/content/${ctaImage}`} />
					</div>
					<footer>
						<Button
							variant="solid"
							onClick={() => {
								history.push('/products/all');
							}}>
							Customize Your PPE
						</Button>
						<a
							href="/request-quote"
							className={classNames({
								[styles.linkButton]: true,
								[styles.linkButtonOutlined]: true
							})}
						>
							Request Product Quote
						</a>
					</footer>
				</Card>
			</div>
		</div>
	);
};

export default Banner;
