import classNames from 'classnames';
import TableForm from './Form';
import styles from './DesktopLayout.module.scss';

const DesktopLayout = ({
	quoteId,
	copy = null,
	data = [],
	schema = {},
	ranges = {},
	highlightIncomplete = false,
	incomplete = [],
	invalid = [],
	handleChange = () => {},
	handleRemoveRow = () => {},
	handleClose = () => {},
	handleUpload = () => {},
	letteringFieldCount = 1,
	isEditable = true,
	isSizeZero = {},
	refresh = () => {},
	allowVirtualSizer = false,
}) => {

	return (
		<div className={classNames(styles.container)}>
			<TableForm
				quoteId={quoteId}
				schema={schema}
				ranges={ranges}
				data={data}
				copy={copy}
				handleChange={handleChange}
				handleRemoveRow={handleRemoveRow}
				handleClose={handleClose}
				handleUpload={handleUpload}
				incomplete={incomplete}
				invalid={invalid}
				highlightIncomplete={highlightIncomplete}
				letteringFieldCount={letteringFieldCount}
				isEditable={isEditable}
				isSizeZero={isSizeZero}
				refresh={refresh}
				allowVirtualSizer={allowVirtualSizer}
			/>
		</div>
	);
};

export default DesktopLayout;
