import Modal from '@components/Modal/Modal';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './QuoteAlertModal.module.scss';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import TextAreaField from '@components/TextAreaField/TextAreaField';
import { useDispatch, useSelector } from 'react-redux';
import { setConfiguratorOptions } from '@store/reducers/configurator';
import MultiUserSuggestField from '@components/MultiSuggestField/MultiUserSuggestField';

const QuoteAlertModal = ({
	quote = {},
	active = false,
	onSubmit = async () => {},
	onClose = () => {},
}) => {
	const dispatch = useDispatch();
	const user = useSelector((state) => state.auth.user);
	const isCSRUser = user.permissions?.includes('csr_access');
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [message, setMessage] = useState(
		quote.alert?.quote_alert_content || '',
	);
	const [messageError, setMessageError] = useState(null);
	const [extraRecipients, setExtraRecipients] = useState(quote.alert?.users || []);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(async () => {
		if (!message) {
			setMessageError('Please enter a message');
			return;
		}

		setIsSubmitting(true);
		await onSubmit({
			quote_alert_id: quote.alert?.quote_alert_id || null,
			quote_alert_content: message,
			order_customer_alert_users: extraRecipients,
		});
		setIsSubmitting(false);
		onClose();
	}, [onSubmit, onClose, message, quote,extraRecipients]);

	/**
	 * Handle Edit Buildout button
	 */

	const handleEditBuildout = useCallback(() => {
		dispatch(
			setConfiguratorOptions({
				quoteId: quote.quote_id,
			}),
		);
	}, [dispatch, quote]);

	useEffect(() => {
		setMessage(quote.alert?.quote_alert_content || '');
	}, [quote, active]);

	return (
		<Modal active={active} onClose={() => onClose()}>
			<h4 className={modalStyles.modalTitle}>Required Edits</h4>
			<div
				className={classNames(modalStyles.modalBody, styles.modalBody)}>
				{isCSRUser && (
					<>
					<TextAreaField
						label="Message"
						value={message}
						onChange={(e) => {
							setMessageError(null);
							setMessage(e.target.value);
						}}
						className={styles.messageField}
						inputClassName={styles.messageFieldInput}
						error={messageError}
					/>
					<div style={{marginTop:8,marginBottom:8}}>
						CC users:
					</div>
					<MultiUserSuggestField
					selections={extraRecipients}
					setSelects={setExtraRecipients}
				/>
				</>
				)}
				{!isCSRUser && (
					<p className={styles.messageContent}>{message}</p>
				)}
			</div>
			<div className={modalStyles.modalActions}>
				<Button variant="outlined" onClick={onClose}>
					Close
				</Button>
				{!isCSRUser && (
					<Button
						variant="solid"
						onClick={() => {
							onClose();
							handleEditBuildout();
						}}>
						Edit Buildout
					</Button>
				)}
				{isCSRUser && (
					<Button
						variant="solid"
						onClick={handleSubmit}
						loading={isSubmitting}>
						Submit
					</Button>
				)}
			</div>
		</Modal>
	);
};

export default QuoteAlertModal;
