import { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import CheckmarkIcon from '@components/Icons/CheckmarkIcon';
import TrashIcon from '@components/Icons/TrashIcon';
import LockedIcon from '@components/Icons/LockedIcon';
import InfoIcon from '@components/Icons/InfoIcon';
import CheckboxField from '@components/CheckboxField/CheckboxField';
import { validation } from '../validation';
import styles from './FieldValue.module.scss';
import Tooltip from '@components/Tooltip/Tooltip';
import SelectField from '@components/SelectField/SelectField';

const FieldValue = ({
	field = {},
	value = '',
	onChange = () => {},
	onRemove = null,
	placeholder = null,
	formatter = null,
	highlight = false,
	formData = {},
	tabIndex = null,
	invalid = false,
	recommendation = undefined,
	range = [],
	isEditable = true,
	isSizeZero = {},
	letteringMaxChar = null,
	disabled = false,
	rowIndex,
	isLocked = false,
	showLock = false,
	onLockToggle = () => { },
}) => {
	const [rangeTooltipVisible, setRangeTooltipVisible] = useState(false);
	const [maxCharsToolTipVisible, setMaxCharsToolTipVisible] = useState(false);
	const [evenTooltipVisible, setEvenTooltipVisible] = useState(false);
	const [warningTooltipVisible, setWarningTooltipVisible] = useState(false);

	const [isValid, setIsValid] = useState(
		!!validation(field, value, { ...formData }),
	);
	const hasRemove = typeof onRemove === 'function';

	const [isDisabled, setIsDisabled] = useState(disabled);

	/**
	 * Handle Change
	 */

	const handleChange = useCallback(
		(e) => {
			onChange(e.target.value);
		},
		[onChange],
	);

	useEffect(() => {
		if (field.sizing_field_name !== 'quantity_pants' && field.sizing_field_name !== 'quantity_coat' && field.sizing_field_name !== 'quantity_coveralls') {
			if (field.product_title === 'Pant' && isSizeZero.pants.includes(rowIndex)) {
				setIsDisabled(true);
				return setIsValid(true);
			} else if (field.product_title === 'Coat' && isSizeZero.coat.includes(rowIndex)) {
				setIsDisabled(true);
				return setIsValid(true);
			} else if (field.product_title === 'coveralls' && isSizeZero.coveralls.includes(rowIndex)) {
				setIsDisabled(true);
				return setIsValid(true);
			} else {
				setIsDisabled(disabled);
			}
		} else if (field.sizing_field_name === 'quantity_pants' && isSizeZero.pants.includes(rowIndex)) {
			return setIsValid(true);
		} else if (field.sizing_field_name === 'quantity_coat' && isSizeZero.coat.includes(rowIndex)) {
			return setIsValid(true);
		} else if (field.sizing_field_name === 'quantity_coveralls' && isSizeZero.coveralls.includes(rowIndex)) {
			return setIsValid(true);
		}

		setIsValid(validation(field, value, formData));
	}, [field, value, formData]);

	/**
	 * Handle Blur
	 */

	const handleBlur = useCallback(() => {
		if (typeof formatter === 'function') {
			handleChange({ target: { value: formatter(value) } });
		}
	}, [formatter, value, handleChange]);

	/**
	 * Indicate will round up for even-only fields
	 */

	const willRoundUp = useMemo(() => {
		return (
			!!value &&
			field.sizing_field_type === 'integer_even' &&
			parseInt(value) % 2 !== 0
		);
	}, [field, value]);

	const inlineStyle = useMemo(() => {
		let style = {};

		if(field.sizing_field_name != 'name' && field.sizing_field_type === 'text' && letteringMaxChar) {
			style.minWidth = Math.max(120, (letteringMaxChar * 16.5) + 14);

			// If field goes next to name, limit max size
			if (field.product_id === null) {
				style.minWidth = Math.min(250, style.minWidth);
			}
		}

		return style;
	}, [field, letteringMaxChar])

	return (
		<div
			className={classNames({
				[styles.fieldValue]: true,
				[styles.fieldValueMid]: field.sizing_field_type === 'text',
				[styles.fieldValueLong]: field.sizing_field_type === 'longtext',
				[styles.fieldValueDropdown]: field.sizing_field_type === 'dropdown',
				[styles.fieldValueWithRemove]: hasRemove,
				[styles.fieldValueHighlight]: !!highlight,
				[styles.fieldValueWarning]: field.showWarning || recommendation,
				[styles.fieldLocked]: isLocked
			})}
			style={inlineStyle}
		>
			{hasRemove && isEditable && (
				<button
					className={styles.removeButton}
					onClick={onRemove}
					tabIndex="-1">
					<TrashIcon className={styles.removeButtonIcon} />
				</button>
			)}
			{[
				'text',
				'longtext',
				'float',
				'integer',
				'integer_inches',
				'integer_even',
			].includes(field.sizing_field_type) && (
					<>
						<input
							type="text"
							className={classNames({
								[styles.input]: true,
								[`field-type-${field.sizing_field_type}`]: true,
							})}
							value={value}
							onChange={handleChange}
							onBlur={handleBlur}
							placeholder={placeholder}
							tabIndex={tabIndex}
							disabled={isDisabled ? true : !isEditable}
						/>

						{field.sizing_field_lettering_requirement && field.sizing_field_type === 'text' && value.length <= letteringMaxChar && (
							<span
								className={classNames({
									[styles.counterAdornment]: true,
									[styles.counterAdornmentValid]: isValid,
								})}
							>
								({value.length} / {letteringMaxChar})
							</span>
						)}
						{showLock && (
							<button
								style={{ width: 24, height: 24, border: 1, borderRadius: 8, borderColor: 'rgb(217,217,217)', display: 'flex', alignItems: 'center', justifyContent: 'center', cursor: 'pointer' }}
								onClick={onLockToggle}
								tabIndex="-1">
								<LockedIcon isLocked={isLocked} />
							</button>
						)}
						{!invalid && !willRoundUp && (
							<div
								className={classNames({
									[styles.checkmarkIndicator]: true,
									[styles.checkmarkIndicatorActive]: isValid,
								})}>
								<CheckmarkIcon />
							</div>
						)}
						{invalid && highlight && range.length > 0 && (
							<div className={styles.rangeTooltipIcon}>
								<InfoIcon
									onClick={(e) => {
										e.stopPropagation();
										setRangeTooltipVisible(
											!rangeTooltipVisible,
										);
									}}
								/>
								<Tooltip
									active={rangeTooltipVisible}
									useCapture={true}
									onClose={() => {
										setRangeTooltipVisible(false);
									}}
									className={styles.rangeTooltip}>
									<span>
										{range[0]}&quot; - {range[1]}&quot;
									</span>
								</Tooltip>
							</div>
						)}
						{invalid && highlight && value.length > letteringMaxChar && (
							<div className={styles.maxCharsTooltipIcon}>
								<InfoIcon
									onClick={(e) => {
										e.stopPropagation();
										setMaxCharsToolTipVisible(
											!maxCharsToolTipVisible,
										);
									}}
								/>
								<Tooltip
									active={maxCharsToolTipVisible}
									useCapture={true}
									onClose={() => {
										setMaxCharsToolTipVisible(false);
									}}
									className={styles.maxCharsTooltip}>
									<span>
										Max Characters {letteringMaxChar}
									</span>
								</Tooltip>
							</div>
						)}
						{field.showWarning && (
							<div className={styles.rangeTooltipIcon}>
								<InfoIcon
									onClick={(e) => {
										e.stopPropagation();
										setRangeTooltipVisible(
											!rangeTooltipVisible,
										);
									}}
								/>
								<Tooltip
									active={rangeTooltipVisible}
									useCapture={true}
									onClose={() => {
										setRangeTooltipVisible(false);
									}}
									className={styles.rangeTooltip}>
									<span>
										{range[0]}&quot; - {range[1]}&quot;
									</span>
								</Tooltip>
							</div>
						)}
						{!invalid && willRoundUp && (
							<div className={styles.evenTooltipIcon}>
								<InfoIcon
									onClick={(e) => {
										e.stopPropagation();
										setEvenTooltipVisible(true);
									}}
								/>
								<Tooltip
									active={evenTooltipVisible}
									useCapture={true}
									onClose={() => {
										setEvenTooltipVisible(false);
									}}
									className={styles.evenTooltip}>
									<span>Will round up to next even size.</span>
								</Tooltip>
							</div>
						)}
					</>
				)}
			{field.sizing_field_type === 'boolean' && (
				<>
					<CheckboxField
						value={parseInt(value) || 0}
						onChange={handleChange}
						className={classNames({
							[styles.booleanField]: true,
							[styles.booleanFieldWarning]: field.showWarning || recommendation,
						})}
						label={parseInt(value) ? 'Yes' : 'No'}
						tabIndex={tabIndex}
						disabled={isDisabled ? true : !isEditable}
					/>
					{field.showWarning &&
						<div className={styles.evenTooltipIcon}>
							<InfoIcon
								onClick={(e) => {
									e.stopPropagation();
									setWarningTooltipVisible(!warningTooltipVisible);
								}}
							/>
							<Tooltip
								active={warningTooltipVisible}
								useCapture={true}
								onClose={() => {
									setWarningTooltipVisible(false);
								}}
								className={styles.warningTooltip}>
								<span>{field.sizing_field_recommendation || "This option is recommended based on your other selections."}</span>
							</Tooltip>
						</div>
					}
				</>
			)}
			
					{
						field.sizing_field_type === 'dropdown' &&
						(

							field.sizing_field_use_alternate_view === '1' ?
								<>
									{field.options.map(option => {
										let optionRecommendation = option?.recommendations ? (option?.recommendations[rowIndex] && Object.keys(option?.recommendations[rowIndex]).length > 0 ? option?.recommendations[rowIndex] : false) : false;
										if (optionRecommendation) {
											optionRecommendation = optionRecommendation[Object.keys(optionRecommendation)[0]];
										}
										let optionDisabled = option?.disabledIndexes ? option?.disabledIndexes[rowIndex] === true : false;
										return (
											<div key={option.sizing_field_option_id} className={classNames({
												[styles.checkboxContainer]: true,
												[styles.hasAlert]: !!optionRecommendation,
												[styles.optionDisabled]: isDisabled ? true : (optionDisabled ? true : !isEditable),
											})}>
												<CheckboxField
													value={value.toLowerCase() === option.sizing_field_option_value.toLowerCase() ? 1 : 0}
													onChange={() => onChange(option.sizing_field_option_value)}
													className={classNames({
														[styles.booleanField]: true,
														[styles.booleanFieldWarning]: field.showWarning,
													})}
													label={option.sizing_field_option_label}
													tabIndex={tabIndex}
													disabled={isDisabled ? true : (optionDisabled ? true : !isEditable)}
													containerStyle={{ marginRight: '15px' }}
												/>

										{(!!optionRecommendation) &&
											<div className={classNames({
												[styles.evenTooltipIcon]: true,
												[styles.checkboxPositioned]: true,
											})}>
												<InfoIcon
													onClick={(e) => {
														e.stopPropagation();
														setWarningTooltipVisible(!warningTooltipVisible);
													}}
												/>
												<Tooltip
													active={warningTooltipVisible}
													useCapture={true}
													onClose={() => {
														setWarningTooltipVisible(false);
													}}
													className={styles.warningTooltip}>
													<span>{optionRecommendation || "This option is recommended based on your other selections."}</span>
												</Tooltip>
											</div>
										}
									</div>
                                );
                            })}
                        </>
                        :
                        <SelectField
                            className={classNames({
                                [styles.dropdown]: true,
                                [`field-type-${field.sizing_field_type}`]: true,
                            })}
                            menuClassName={styles.dropdownMenu}
                            activeClassName={styles.dropdownActive}
                            inputClassName={styles.dropdownInput}
                            loaderClassName={styles.dropdownLoader}
                            value={value}
                            onChange={handleChange}
                            tabIndex={tabIndex}
                            options={field.options.map(option => {
								if (option?.disabledIndexes && option?.disabledIndexes[rowIndex] === true) {
									return {
										...option,
										disabled: true
									}
								}
								return option;
                            })}
                            labelField="sizing_field_option_label"
                            valueField="sizing_field_option_value"
                            disabled={isDisabled ? true : !isEditable}
                        />

						)
					}

				</div>
			);
};

			export default FieldValue;
