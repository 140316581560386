import React from 'react';
import { Link, useRouteMatch } from 'react-router-dom';
import classNames from 'classnames';
import styles from './NavigationItem.module.scss';

const NavigationItem = ({ item }) => {
	const isActive = useRouteMatch({ path: item.path, exact: false });

	return (
		<li
			className={classNames({
				[styles.navItem]: true,
				[styles.activeNavItem]: isActive,
			})}>
			{!!item.path && (
				<Link to={item.path}>
					{item.icon}
					<span>{item.title}</span>
				</Link>
			)}
			{!!item.onClick && (
				<p onClick={item.onClick}>
					{item.icon}
					<span>{item.title}</span>
				</p>
			)}
			{!!item.indicator && <div className={styles.indicator} />}
		</li>
	);
};

export default NavigationItem;
