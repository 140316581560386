import {useCallback, useEffect, useMemo, useReducer, useState} from 'react';
import styles from './LetteringSummary.module.scss';
import {useSelector} from "react-redux";
import {useLetteringOptions} from "@components/LetteringModal/hooks/useLetteringOptions";
import {letteringReducer} from "@components/LetteringModal/reducer";
import {useSyncLetteringValues} from "@components/LetteringModal/hooks/useSyncLetteringValues";
import {useMaxCharLengths} from "@components/LetteringModal/hooks/useMaxCharLengths";
import {useLetteringValidation} from "@components/LetteringModal/hooks/useLetteringValidation";
import {useLetteringUtils} from "@components/LetteringModal/hooks/useLetteringUtils";
import Http from "@utilities/Http";
import {API_ROOT} from "@config";
import LetteringStyleForm from "@components/LetteringModal/components/LetteringStyleForm/LetteringStyleForm";
import LetteringItem from "@components/LetteringSummary/components/LetteringItem/LetteringItem";
import classNames from "classnames";

const LetteringSummary = ({
	active=true,
	productOptions = [],
	productOptionValues = [],
	onSave = () => {},
	productLineId = null,
	optionIsAvailable = () => {},
	isEditable = true,
	data = [],
	dispatch = ()=>{},
	placement = '',
	setPlacement = ()=>{},
	placements = [],
    allPlacements = [],
	productId = null,
	values = [],
	}) => {


	const {letteringCategoryId} = useSelector(
		(state) => state.staticData
	);


	// loaders
	const [isSubmitting, setIsSubmitting] = useState(false);

	// track form interaction
	const [isPristine, setIsPristine] = useState(true);

	// show validation
	const [didAttemptSubmit, setDidAttemptSubmit] = useState(false);

	/**
	 * Get lettering-related options (patch, patch color, style)
	 */

	const options = useLetteringOptions(productOptions, optionIsAvailable);

	useSyncLetteringValues(dispatch, productOptionValues, active);

	/**
	 * Index data by lettering position
	 */

	const indexed = useMemo(() => {
		return data.reduce((indexed, value) => {
			if (!indexed[value.lettering_placement_id]) {
				value.product_id = productId;
				indexed[value.lettering_placement_id] = value;
			}

			return indexed;
		}, {});
	}, [data, productId]);

	/**
	 * Max char lengths for patch/style combination
	 */

	const [charLengths, availableStyles] = useMaxCharLengths(indexed);

	/**
	 * Field validation
	 */

	const errors = useLetteringValidation(indexed, charLengths);

	/**
	 * Utility methods
	 */

	const {
		initializeLetteringItem,
		getPatchNumRows,
		getDefaultPatchColor,
		getLetteringStyleColor,
		getLetteringStyleDescription,
		getOptionValueById,
	} = useLetteringUtils(options, productOptionValues);
	const saveLetteringItem = () => {
		setPlacement(null);
		try {
			const defaultPatchColor = getDefaultPatchColor();
			onSave(data.map(item => {
				return {
					...item,
					quote_product_option_value_lettering_match_main_shell: item.product_option_value_id_patch_color == defaultPatchColor.product_option_value_id ? 1 : 0,
					product_option_category_id: letteringCategoryId
				};
			})
			, 'add');
		} finally {
			setIsSubmitting(false);
		}
	}

	const removeLetteringItem = async (lettering_placement_id) =>{
		const existingItem = data.find(
			(item) =>
				item.lettering_placement_id ==
				lettering_placement_id,
		);
		const numRows = getPatchNumRows(existingItem.product_option_value_id);

		dispatch({
			type: 'remove',
			placement: lettering_placement_id,
			rows: numRows,
		});

		try {
			await onSave(data.filter(item=>item.lettering_placement_id === lettering_placement_id), 'remove');
		} finally {
			setIsSubmitting(false);
		}
	}

	/**
	 * Handle Field Change
	 */

	const handleChange = useCallback(
		(e, letteringPlacement = null) => {
			if (!isEditable) return;

			setDidAttemptSubmit(false);
			setIsPristine(false);

			// placement to create/update
			const selectedPlacement = letteringPlacement || placement;

			// find existing item in placement
			const existingItem = data.find(
				(item) =>
					item.lettering_placement_id ==
					selectedPlacement.lettering_placement_id,
			);

			// create new item
			if (!existingItem) {
				const item = initializeLetteringItem({
					// set product line
					product_line_id: productLineId,
					// set placement id
					lettering_placement_id: selectedPlacement.lettering_placement_id,
					lettering_placement_index: selectedPlacement.lettering_placement_index,
					// set changed value
					[e.target.name]: e.target.value,
				});

				const patch =
					e.target.name === 'product_option_value_id'
						? getOptionValueById(e.target.value)
						: null;

				// if patch selected, set lettering arched/inverted
				if (patch) {
					item.product_option_value_lettering_is_arched =
						patch?.product_option_value_lettering_is_arched;
					item.product_option_value_lettering_is_inverted =
						patch?.product_option_value_lettering_is_inverted;
					item.product_option_value_display_option_code = patch.product_option_value_display_option_code;
					item.product_option_value_description = patch.product_option_value_description;
				}

				dispatch({
					type: 'create',
					payload: item,
					rows:
						e.target.name === 'product_option_value_id' // if patch selected
							? getPatchNumRows(e.target.value)
							: 1,
				});

				return;
			}

			// update existing item
			if (existingItem) {
				const defaultPatchColor = getDefaultPatchColor();

				switch (e.target.name) {
					// patch change
					case 'product_option_value_id': {
						const patch = getOptionValueById(e.target.value);

						const payload = {
							// selected patch
							product_option_value_id: e.target.value,
							// arched
							product_option_value_lettering_is_arched:
							patch?.product_option_value_lettering_is_arched,
							// inverted
							product_option_value_lettering_is_inverted:
							patch?.product_option_value_lettering_is_inverted,
							product_option_value_display_option_code: patch.product_option_value_display_option_code,
							product_option_value_description: patch.product_option_value_description
						};

						// set default patch color if sewn direct
						if (
							parseInt(
								patch?.product_option_value_lettering_is_sewn_direct,
							)
						) {
							payload.product_option_value_id_patch_color =
								defaultPatchColor?.product_option_value_id ||
								null;
						}

						dispatch({
							type: 'updatePatch',
							placement: existingItem.lettering_placement_id,
							rows: getPatchNumRows(e.target.value),
							prevRows: getPatchNumRows(
								existingItem.product_option_value_id,
							),
							payload,
						});

						break;
					}
					// custom text change
					case 'quote_product_option_value_lettering_custom_text': {
						dispatch({
							type: 'updateDetails',
							rows: 1,
							placement: existingItem.lettering_placement_id,
							payload: { [e.target.name]: e.target.value },
						});

						break;
					}
					case 'quote_product_option_value_message': {
						dispatch({
							type: 'updateDetails',
							rows: 1,
							placement: existingItem.lettering_placement_id,
							payload: { [e.target.name]: e.target.value },
						});

						break;
					}
					// style, patch color, type change (sync to other rows within patch)
					case 'product_option_value_id_style':
					case 'product_option_value_id_patch_color':
					case 'quote_product_option_value_lettering_type':
					{
						const payload = { [e.target.name]: e.target.value };

						// set lettering preview color
						if (
							e.target.name ===
							'product_option_value_id_style'
						) {
							payload.product_option_value_lettering_color =
								getLetteringStyleColor(e.target.value);
							payload.lettering_style_description = getLetteringStyleDescription(e.target.value);
						}

						const rows = e.target.name === 'quote_product_option_value_lettering_type' ? 1 : getPatchNumRows(
							existingItem.product_option_value_id,
						);

						dispatch({
							type: 'updateDetails',
							placement: existingItem.lettering_placement_id,
							rows: rows,
							payload,
						});

						if (
							e.target.name ===
							'product_option_value_id_patch_color'
						) {
							const additionalPatchColors = Object.values(
								data,
							).filter(
								(value) =>
									value.product_option_value_id_patch_color !=
									defaultPatchColor?.product_option_value_id,
							);

							additionalPatchColors.forEach((value) => {
								if (
									value.lettering_placement_id !=
									selectedPlacement.lettering_placement_id &&
									value.product_option_value_id_patch_color !=
									e.target.value &&
									e.target.value !=
									defaultPatchColor.product_option_value_id
								) {
									dispatch({
										type: 'updateDetails',
										placement:
										value.lettering_placement_id,
										rows: getPatchNumRows(
											value.product_option_value_id,
										),
										payload: {
											product_option_value_id_patch_color:
												null,
										},
									});
								}
							});
						}
					}

						break;
				}
			}
		},
		[
			placement,
			data,
			initializeLetteringItem,
			productLineId,
			getPatchNumRows,
			getLetteringStyleColor,
			getDefaultPatchColor,
			getOptionValueById,
		]
	);

	/**
	 * Handle Clear
	 */

	const handleClear = useCallback(() => {
		if (!isEditable) return;

		if(placement) {
			setDidAttemptSubmit(false);
			setIsPristine(false);

			const item = indexed[placement.lettering_placement_id];
			const numRows = getPatchNumRows(item?.product_option_value_id);

			dispatch({
				type: 'remove',
				placement: placement.lettering_placement_id,
				rows: numRows,
			});
		}
	}, [dispatch, isEditable, placement, getPatchNumRows, indexed]);

	return (
		<div className={classNames([styles.container])}>
			<LetteringStyleForm
				data={indexed}
				placements={placements}
				allPlacements={allPlacements}
				selectedPlacement={placement}
				selectedPlacements={data.map(item => item.lettering_placement_id)}
				charLengths={charLengths}
				availableStyles={availableStyles}
				setPlacement={setPlacement}
				options={options}
				onChange={handleChange}
				onClear={handleClear}
				errors={
					didAttemptSubmit && !!Object.keys(errors).length
						? errors
						: {}
				}
				getPatchNumRows={getPatchNumRows}
				getOptionValueById={getOptionValueById}
				getDefaultPatchColor={getDefaultPatchColor}
				isEditable={isEditable}
				onAdd={() => {
					saveLetteringItem(placement)
				}}
				productId={productId}
			/>
			<div className={classNames([styles.container])}>
			{data.filter(item => {
				return (!placement || item.lettering_placement_id !== placement.lettering_placement_id) &&
					values.map(value => value.lettering_placement_id).includes(item.lettering_placement_id) &&
					item.lettering_row_number == 0;
			}).sort((a,b)=>a.lettering_placement_id-b.lettering_placement_id).map((savedItem,index) => {
				let lettersArray = [];
				const numRows = getPatchNumRows(savedItem.product_option_value_id);
				if(numRows > 1) {
					new Array(numRows).fill({}).map((_, key) => {
						const placementId =
							parseInt(savedItem.lettering_placement_id) + key;
						if(indexed[placementId]?.quote_product_option_value_lettering_custom_text) {
							lettersArray.push('Custom: ' + indexed[placementId]?.quote_product_option_value_lettering_custom_text);
						} else  if(indexed[placementId]) {
							lettersArray.push(indexed[placementId].quote_product_option_value_lettering_type);
						}
					});
				}

				return (<LetteringItem key={savedItem.lettering_placement_id + '-'+index} item={savedItem} onRemove={removeLetteringItem} onSelect={setPlacement} placements={placements} isEditable={isEditable} lettersArray={lettersArray} /> )
			})}
			</div>
		</div>
	);
};

export default LetteringSummary;
