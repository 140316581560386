import React, { useCallback } from 'react';
import Button from '@components/Button/Button';
import PlusIcon from '@components/Icons/PlusIcon';
import ChevronIcon from '@components/Icons/ChevronIcon';
import SelectField from '@components/SelectField/SelectField';
import styles from './Sort.module.scss';

const Sort = ({
	sortOptions = [],
	sortField = '',
	sortDirection = 'ASC',
	sortPlaceholder = 'Sort',
	onSortChange = () => {},
	onAddItem = null,
	isSorting = false,
}) => {
	/**
	 * Handle Sort Change
	 */

	const handleChange = useCallback(
		async (e) => {
			const value = e.target.value;
			const option = sortOptions.find((option) => option.value === value);
			const parts = value?.split('-');
			if(parts?.length > 1) {
				let [name, direction] = parts;
				direction = direction.toUpperCase();
				onSortChange([value, direction]);
			}else{
				onSortChange([
					value,
					value === sortField // toggle direction
						? sortDirection === 'ASC'
							? 'DESC'
							: 'ASC'
						: option?.sort || 'ASC',
				]);
			}
		},
		[sortOptions, sortField, sortDirection, onSortChange],
	);

	return (
		<div className={styles.sortContainer}>
			<SelectField
				value={sortField}
				options={sortOptions}
				onChange={handleChange}
				className={styles.sortField}
				inputClassName={styles.sortFieldInput}
				menuClassName={styles.sortFieldMenu}
				placeholder={sortPlaceholder}
				loading={isSorting}
				allowNull={true}
				indicator={<ChevronIcon fill="rgba(34, 34, 34, 0.5)" />}
			/>
			{typeof onAddItem === 'function' && (
				<Button variant="square-outlined" onClick={onAddItem}>
					<PlusIcon />
				</Button>
			)}
		</div>
	);
};

export default Sort;
