import { createSlice } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { fetchUser } from '@store/thunks/fetch-user';

const initialState = {
	token: null,
	user: null,
	authSettled: false,
};

const authSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		setToken(state, action) {
			return {
				...state,
				token: action.payload,
			};
		},
		setUser(state, action) {
			return {
				...state,
				user: action.payload,
			};
		},
		logout() {
			localStorage.removeItem('persist:auth');
			return {
				...initialState,
				authSettled: true,
			};
		},
		acknowledgeNotifications(state) {
			return {
				...state,
				user: {
					...state.user,
					hasNotifications: false,
				},
			};
		}
	},
	extraReducers: {
		[fetchUser.pending]: (state) => {
			return {
				...state,
				isFetchingUser: true,
			};
		},
		[fetchUser.fulfilled]: (state, action) => {
			return {
				...state,
				user: action.payload.user,
				authSettled: true,
				isFetchingUser: false,
			};
		},
		[fetchUser.rejected]: () => {
			return {
				...initialState,
				authSettled: true,
				isFetchingUser: false,
			};
		},
	},
});

export const persistConfig = {
	key: 'auth',
	storage,
	blacklist: ['authSettled'],
};

export const { setToken, setUser, logout,acknowledgeNotifications } = authSlice.actions;
export default persistReducer(persistConfig, authSlice.reducer);
