import { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import date from '@utilities/date';
import classNames from 'classnames';
import Button from '@components/Button/Button';
import styles from './OrderActions.module.scss';
import CustomerAlert from '../CustomerAlert/CustomerAlert';
import { formatCurrency } from '@utilities/currency';
import { isApproved, isFinalized, isSubmitted } from '@utilities/orders';
import TCMValidationModal from './components/TCMValidationModal/TCMValidationModal';

const OrderActions = ({
	order,
	onSubmit = () => {},
	onTCM = async () => {},
	totalPrice = 0,
	rejected = false,
	isSubmitting = false,
	isSubmittingTCM = false,
	setIsSubmittingTCM = () => {},
	isEditable = true,
}) => {
	const user = useSelector((state) => state.auth.user);
	const hasCsrAccess = user?.permissions?.includes('csr_access');
	const isCSRUser = hasCsrAccess && order?.user_id != user?.user_id;
	const demoModeActive = useSelector((state) => state.demoMode.active);
	const [tcmValidationModalActive, setTCMValidationModalActive] =
		useState(false);
	const orderSubmissionDisabled = useSelector(
		(state) => state.staticData.orderSettings['disable_order_submission'],
	);
	const isAdmin = user?.permissions?.includes('access_admin');

	/**
	 * Validate for TCM Submit
	 */

	const errors = useMemo(() => {
		const errors = {};

		if (!order.order_tcm_sales_rep_1_id) {
			errors['order_tcm_sales_rep_1_id'] =
				'Please enter a TCM Sales Rep ID';
		}

		return errors;
	}, [order]);

	/**
	 * TCM Submit Handler
	 */

	const handleSendToTCM = useCallback(async () => {
		if(!isSubmittingTCM) {
			if (Object.keys(errors).length) {
				setTCMValidationModalActive(true);
				return;
			}

			setIsSubmittingTCM(true);

			try {
				await onTCM();
			} catch (error) {
				console.log(error);
			} finally {
				setIsSubmittingTCM(false);
			}
		}
	}, [onTCM, errors, isSubmittingTCM]);

	return (
		<div
			className={classNames({
				[styles.orderActionsContainer]: true,
				[styles.orderActionsRejected]: rejected,
			})}>
			<div className={styles.timestamps}>
				{isSubmitted(order) && !!order.order_submitted_timestamp && (
					<p className={styles.orderSubmittedDate}>
						Order Submitted:{' '}
						<b>
							{date(order.order_submitted_timestamp).format(
								'M/DD/YY',
							)}
						</b>
					</p>
				)}
				{isFinalized(order) && !!order.order_tcm_submitted_timestamp && (
					<p className={styles.orderApprovedDate}>
						Order Approved:{' '}
						<b>
							{date(order.order_tcm_submitted_timestamp).format(
								'M/DD/YY',
							)}
						</b>
					</p>
				)}
			</div>
			
			<CustomerAlert order={order} onResubmit={onSubmit}>
				<div className={styles.orderActionsRequiresEdits}>
					{(isCSRUser || (hasCsrAccess && order?.user_id == user?.user_id)) && isApproved(order) && (!isFinalized(order) || order.order_status === 'Cancelled') && (
						<Button
							variant="solid"
							onClick={handleSendToTCM}
							loading={isSubmittingTCM}>
							Approve and Send to TCM
						</Button>
					)}
					{!isCSRUser &&
						user.user_organization_pricing === '1' &&
						order.order_status === 'Requires Edits' &&
						!!order.quotes.length && (
							<Button
								variant="outlined"
								onClick={onSubmit}
								loading={isSubmitting}
								disabled={orderSubmissionDisabled === '1' && !isAdmin && !hasCsrAccess ? true : false}
							>
								Resubmit Order
							</Button>
						)}
					{!isCSRUser &&
						user.user_organization_pricing === '1' &&
						order.order_status === 'Not Submitted' &&
						!!order.quotes.length &&
						isEditable && (
							<Button
								variant="outlined"
								onClick={onSubmit}
								loading={isSubmitting}
								disabled={orderSubmissionDisabled === '1' && !isAdmin && !hasCsrAccess ? true : false}
							>
								Submit Order
							</Button>
						)}
					{!demoModeActive && (
						<span className={styles.orderPrice}>
							Total Cost: {formatCurrency(totalPrice)}
						</span>
					)}
				</div>
			</CustomerAlert>
			<TCMValidationModal
				active={tcmValidationModalActive}
				onClose={() => setTCMValidationModalActive(false)}
				errors={errors}
			/>
		</div>
	);
};

export default OrderActions;
