import React,{useState,useEffect} from 'react';
import {API_ROOT} from '@config';
import classNames from 'classnames';
import styles from './MultiUserSuggestField.module.scss';
import SuggestField from '@components/SuggestField/SuggestField';

export default function MultiUserSuggestField({selections = [],setSelects,disabled,additionalParams = ''}){

	const [inputEmail,setInputEmail] = useState('');
	const [error,setError] = useState('');
	const [,update] = useState(0);
 
	const removeSelected = (index) => {
		if(disabled){
			return;
		}
		let newSelected = selections;
		newSelected.splice(index,1);
		setSelects(newSelected);
		update(Math.random());
	}

	const addSelected = (user) => {
		if(disabled){
			return;
		}
		let newSelected = selections;
		newSelected.push(user);
		setSelects(newSelected);
		setInputEmail('');
		update(Math.random());
	}

	return (
		<div className={styles.multiSuggestField}>
			<SuggestField
				placeholder="Search or Enter Email"
				value={inputEmail}
				valueField="user_email"
				labelExtractor={(user) =>
					`${user.user_display_name} (${user.user_email})`
				}
				url={`${API_ROOT}/organizations/users`}
				additionalParams={additionalParams}
				getResults={(data) => data.users}
				onTextChange={(e) => {
					setError('');
					setInputEmail(e.target.value);
				}}
				onSelect={(user) => {
					setError('');
					addSelected(user);
				}}
				className={classNames({
					[styles.emailField]: true,
				})}
				inputClassName={styles.emailInput}
				error={error}
				noResultsMessage="No Results"
			/>
			<div className={styles.selectionContainer}>
			{selections.map((selection,index)=>(
				<div className={styles.selection} key={index}>
					<div className={styles.selectionLabel}>{selection['user_email']}</div>
					<div className={styles.selectionRemove} onClick={()=>removeSelected(index)}>X</div>
				</div>
			))}
			</div>
		</div>
	)
}