import { useCallback, useEffect, useMemo, useRef } from 'react';

import Card from '@components/Card/Card';
import Screen from '@components/Screen/Screen';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { useQueryParams } from '@hooks/useQueryParams';
import PublicBanner from '@components/PublicBanner/PublicBanner';
import RequestQuoteForm from './components/RequestQuoteForm/RequestQuoteForm';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';
import { setRAQFormData } from '@store/reducers/raq';
import styles from './RequestQuote.module.scss';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';

const RequestQuote = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const params = useQueryParams(location);
	const { user, authSettled } = useSelector((state) => state.auth);
	const isAuthenticated = useRef(null);
	const content = useSelector((state) => state.staticData.content);
	const bannerImage =
		content['request-quote-page']?.indexed['banner']['content_item_image'];

	/**
	 * Redirect if logged in and not standard user
	 */

	useEffect(() => {
		if (user && !user?.roles?.find((role) => role.role_token === 'user')) {
			history.push('/dashboard');
		}
	}, [user, history]);

	/**
	 * Handle Submit
	 */

	const handleSubmit = useCallback(
		async (formData) => {
			const { data } = await Http().post(
				`${API_ROOT}/request-quote`,
				formData,
			);

			dispatch(
				setRAQFormData({ ...formData, hubspot_contact_id: data.id }),
			);

			history.push('/request-quote/success');
		},
		[history, dispatch],
	);

	useEffect(() => {
		dispatch(setRAQFormData(null));
	}, [dispatch]);

	/**
	 * Header Content
	 */

	const headerContent = useMemo(() => {
		if (user) {
			return <WelcomeHeader />;
		}

		return (
			<PublicBanner
				backgroundImage={
					bannerImage
						? `${API_ROOT}/uploaded/content/${bannerImage}`
						: null
				}
				className={styles.publicBanner}>
				<div
					className={styles.cta}
					dangerouslySetInnerHTML={{
						__html: content['request-quote-page']?.indexed[
							'banner'
						]['content_item_html'],
					}}
				/>
			</PublicBanner>
		);
	}, [bannerImage, content, user]);

	/**
	 * Initial form data
	 */

	const initialData = useMemo(() => {
		const data = {};

		if (params.quote_id) {
			data.notes = `Quote #${params.quote_id.padStart(4, '0')}`;
		}

		if (user) {
			const userData = {
				profile_first_name: user.profile_first_name,
				profile_last_name: user.profile_last_name,
				profile_title: user.profile_title,
				profile_phone: user.profile_phone,
				user_email: user.user_email,
				fire_department_id: user.fire_department_id,
				fire_department_hubspot_id: user.fire_department_hubspot_id,
				fire_department_name: user.fire_department_name,
				fire_department_zip: user.fire_department_zip,
			};

			Object.keys(userData).forEach((key) => {
				data[key] = userData[key] || '';
			});
		}

		return data;
	}, [user, params]);

	/**
	 * Handle logout issues (because this page is both public and non-public)
	 */

	useEffect(() => {
		// keep track of whether user was logged in on load
		if (isAuthenticated.current === null && authSettled) {
			isAuthenticated.current = !!user;
		}

		// if logged out, redirect
		if (isAuthenticated.current && !user) {
			history.push('/login');
		}
	}, [user, authSettled, history]);

	return (
		<Screen
			hideSidebar={!user}
			hideFooter={true}
			headerContent={headerContent}
			pageContentClassName={styles.pageContent}>
			<Helmet>
				<title>Request a Quote</title>
			</Helmet>
			<Card className={styles.contentCard}>
				<h3>Request a Quote</h3>
				{authSettled && (
					<RequestQuoteForm
						onSubmit={handleSubmit}
						data={initialData}
					/>
				)}
			</Card>
		</Screen>
	);
};

export default RequestQuote;
