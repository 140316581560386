import React, { useCallback, useEffect, useRef, useState } from 'react';
import http from '@utilities/Http';
import { API_ROOT } from '@config';
import axios, { CancelToken } from 'axios';
import { getParamString } from '@utilities/getParamString';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';
import Screen from '@components/Screen/Screen';
import Button from '@components/Button/Button';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import Collection from '@components/Collection/Collection';
import Filters from './components/Filters/Filters';
import QuotesListItem from './components/QuotesListItem/QuotesListItem';
import ConfirmationModal from '@components/ConfirmationModal/ConfirmationModal';
import { useDispatch, useSelector } from 'react-redux';
import AssignQuoteToOrderOverlayForm from '@components/AssignQuoteToOrderOverlayForm';
import CopyToOrderModal from '@components/CopyToOrderModal/CopyToOrderModal';
import modalStyles from '@components/Modal/Modal.module.scss';
import {setConfiguratorOptions} from "@store/reducers/configurator";
/**
 * Items per Page
 */

const perPage = 10;

/**
 * Sort Options
 */

const sortOptions = [
	{ label: 'Quote Name', value: 'quote_name', sort: 'ASC' },
	{ label: 'Quote Number', value: 'quote_name', sort: 'ASC' },
	{ label: 'Price', value: 'price', sort: 'DESC' },
	{ label: 'Date Created', value: 'quote_created_timestamp', sort: 'DESC' },
	{ label: 'Last Modified', value: 'quote_modified_timestamp', sort: 'DESC' },
	{ label: 'Fire Department', value: 'fire_department_name', sort: 'ASC' },
];

/**
 * Filters Schema
 */

const filtersSchema = {
	'quote_name': '',
	'quote_number': '',
	'product_line_description': '',
	'fire_department_id': '',
	'user_id': '',
	'status': '',
	'organization_id': '',
};

const Quotes = () => {
	const fetchRequestSource = useRef(null);
	const history = useHistory();
	const dispatch = useDispatch();

	const [quotes, setQuotes] = useState([]);
	const [page, setPage] = useState(0);
	const [isLastPage, setIsLastPage] = useState(false);
	const [sortField, setSortField] = useState('');
	const [sortDirection, setSortDirection] = useState('DESC');
	const isDistributorAdmin = useSelector((state) =>
		state.auth.user?.permissions?.includes('manage_organization'),
	);
	const user = useSelector((state) => state.auth.user);
	const [filters, setFilters] = useState({
		...filtersSchema,
		user_id: isDistributorAdmin ? user?.user_id : null,
	});
	const [initialized, setInitialized] = useState(false);
	const [isFetching, setIsFetching] = useState(false);
	const [isRemoving, setIsRemoving] = useState(false);
	const [quotePendingDelete, setQuotePendingDelete] = useState(null);
	const [selectingOrderToAssign, setSelectingOrderToAssign] = useState(null);
	const [selectedCopyQuote, setSelectedCopyQuote] = useState(null);
	const [copyModalActive, setCopyModalActive] = useState(false);
	/**
	 * Fetch Quotes
	 */

	const fetchQuotes = useCallback(
		(
			filterString = '',
			paginationString = '',
			sortField = null,
			sortDirection = null,
		) => {
			// cancel any pending request
			fetchRequestSource.current?.cancel();
			// create new cancel token
			fetchRequestSource.current = CancelToken.source();

			setIsFetching(true);

			return http()
				.get(
					`${API_ROOT}/quotes?${filterString}${paginationString}&sort=${sortField}&direction=${sortDirection}`,
					{ cancelToken: fetchRequestSource.current?.token },
				)
				.then(({ data }) => {
					setIsFetching(false);
					return data.quotes;
				})
				.catch((err) => {
					if (!axios.isCancel(err)) {
						setIsFetching(false);
					}
					return [];
				})
				.finally(() => {
					setInitialized(true);
					setIsRemoving(false);
				});
		},
		[],
	);

	/**
	 * Copy Quote
	 */

	const copyQuote = useCallback((quote) => {
		setIsRemoving(true);
		return http()
			.post(`${API_ROOT}/quotes/copy/${quote.quote_id}`)
			.then(({ data }) => {
				window.scrollTo({ top: 0 });
				// trigger refetch
				setFilters((filters) => ({ ...filters }));
			});
	}, []);

	/**
	 * Reset Page on Sort/Filter change
	 */

	useEffect(() => {
		setPage(0);
		setIsLastPage(false);
	}, [sortField, sortDirection, filters]);

	function reloadPage() {
		setFilters((filters) => ({ ...filters }));
	}

	useEffect(() => {
		if(initialized) {
			dispatch(
				setConfiguratorOptions({
					onClose: reloadPage,
				}),
			);
		}
	}, [initialized]);

	/**
	 * Fetch Quotes on Sort/Filter Change
	 */

	useEffect(() => {
		fetchQuotes(
			// filters
			getParamString(filters),
			// pagination
			getParamString({ take: perPage, skip: page * perPage }),
			// sort
			sortField,
			sortDirection,
		).then((quotes) => {
			setIsLastPage(quotes.length < perPage);
			setQuotes((current) => [...(page === 0 ? [] : current), ...quotes]);
		});
	}, [fetchQuotes, sortField, sortDirection, filters, page]);

	/**
	 * Handle remove quote
	 */

	const handleRemove = useCallback((quote) => {
		setIsRemoving(true);
		return http()
			.delete(`${API_ROOT}/quotes/${quote.quote_id}`)
			.then(() => {
				window.scrollTo({ top: 0 });
				// trigger refetch
				setFilters((filters) => ({ ...filters }));
				// close modal
				setQuotePendingDelete(null);
			});
	}, []);

	const openReorderModal = useCallback((quote) => {
		setSelectedCopyQuote(quote);
		setCopyModalActive(true);
	}, []);


	const handleCopyModalClose = useCallback(
		(order) => {
			setCopyModalActive(false);
			setSelectedCopyQuote(null);
			if (order) {
				history.push(`/orders/${order.order_id}`);
			}

		},
		[history],
	);

	return (
		<Screen
			headerContent={
				<>
					<WelcomeHeader />
					<Filters filters={filters} setFilters={setFilters} />
				</>
			}
			loading={isFetching && !initialized}>
			<Collection
				title="Quotes"
				items={quotes}
				itemIdField="quote_id"
				siblingItemField="sibling_quote_id"
				component={QuotesListItem}
				page={page}
				onPageChange={setPage}
				isLastPage={isLastPage}
				sortField={sortField}
				sortDirection={sortDirection}
				sortOptions={sortOptions}
				onSortChange={([field, direction]) => {
					setSortField(field);
					setSortDirection(direction);
				}}
				isFetching={isFetching}
				noResultsAction={
					<Link to="/products">
						<Button variant="outlined">View All Products</Button>
					</Link>
				}
				onEdit={() => setFilters((filters) => ({ ...filters }))}
				onRemove={setQuotePendingDelete}
				isRemoving={isRemoving}
				copyQuote={copyQuote}
				setSelectingOrderToAssign={setSelectingOrderToAssign}
				handleReorder={openReorderModal}
			/>
			<CopyToOrderModal
					active={copyModalActive}
					onClose={handleCopyModalClose}
					quoteId={selectedCopyQuote}
					className={modalStyles?.withSidebar}
				/>
			<ConfirmationModal
				active={!!quotePendingDelete}
				title="Delete Quote"
				message="Are you sure you want to delete this quote?"
				onClose={() => setQuotePendingDelete(null)}
				onConfirm={() => handleRemove(quotePendingDelete)}
			/>
			{selectingOrderToAssign !== null ? (
				<AssignQuoteToOrderOverlayForm
					quoteId={selectingOrderToAssign}
					portalId="overlay-portal"
					closeAction={() => setSelectingOrderToAssign(null)}
				/>
			) : null}
		</Screen>
	);
};

export default Quotes;
