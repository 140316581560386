import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import date from '@utilities/date';
import { formatCurrency } from '@utilities/currency';
import TextField from '@components/TextField/TextField';
import OrderItemPreview from '@screens/Order/components/OrderItemPreview/OrderItemPreview';
import ArrowCircleIcon from '@components/Icons/ArrowCircleIcon';
import collectionStyles from '@components/Collection/Collection.module.scss';
import styles from './QuotesListItem.module.scss';
import { setConfiguratorOptions } from '@store/reducers/configurator';
import QuoteIndicator from '../QuotesIndicator/QuoteIndicator';
import { getQuoteTotalPrice } from '@utilities/pricing';
import CountIcon from '@components/Icons/CountIcon';
import VariableProductImage from '@components/VariableProductImage';
import Menu from '@components/Menu/Menu';
import { getQuoteDisplayNumber, capitalize } from '@utilities/methods';
import {isFinalized, isQuoteFinalized, isQuoteSubmitted, isSubmitted} from "@utilities/orders";

const QuotesListItem = ({
	item: quote,
	siblingItem: siblingQuote,
	onRemove = () => {},
	onEdit = () => {},
	copyQuote = async () => {},
	setSelectingOrderToAssign = () => {},
	handleReorder = () => {},
}) => {
	const dispatch = useDispatch();
	const demoModeActive = useSelector((state) => state.demoMode.active);
	const user = useSelector((state) => state.auth.user);
	const [isCopying, setIsCopying] = useState(false);
	const [isMounted, setIsMounted] = useState(true);

	const handleCopyQuote = useCallback(async () => {
		setIsCopying(true);
		try {
			await copyQuote(quote);
		} finally {
			isMounted && setIsCopying(false);
		}
	}, [quote, copyQuote, isMounted]);

	/**
	 * Menu Actions
	 */

	const menuActions = useMemo(() => {
		const actions = [];

		if(user.user_id === quote.user_id
			&& !isQuoteSubmitted({order_status: quote.order_status})
			&& !isQuoteFinalized({order_status: quote.order_status})
		) {
			actions.push({
				label: 'Delete quote',
				action: () => onRemove(quote),
			});
		}

		actions.push({
			label: 'Copy Quote',
			action: handleCopyQuote,
		});

		if (quote.order_id === null){
			actions.push({
				label: 'Assign To Order',
				action: () => setSelectingOrderToAssign(quote.quote_id),
			});
		}
		if(isFinalized({ order_status: quote.order_status }) && quote.quote_not_for_reorder !== '1') {
			actions.push({
				label: 'Reorder',
				action: () => handleReorder(quote.quote_id),
			});
		}
		return actions;
	}, [onRemove, quote, handleCopyQuote, setSelectingOrderToAssign, user.user_id]);


	const getQuoteStatusText = (quote) => {
		const status = quote.quote_status ? capitalize(quote.quote_status) : (quote.order_status ? capitalize(quote.order_status) : 'Not Submitted')
		if(status === 'Cancelled') {
			return 'Not Submitted';
		}
		return status;
	}

	return (
		<div
			className={classNames({
				[collectionStyles.collectionItem]: true,
				[styles.collectionItem]: true,
				[styles.demoModeActive]: demoModeActive,
			})}>
			<QuoteIndicator quote={quote} />
			<div
				className={classNames({
					[collectionStyles.collectionItemImageContainer]: true,
					[styles.collectionItemImageContainer]: true,
				})}
				onClick={() =>
					dispatch(
						setConfiguratorOptions({
							quoteId: quote.quote_id,
							onClose: onEdit,
						}),
					)
				}>
				<div>
					{quote.product_line_id ? (
						<OrderItemPreview quote={quote} />
					) : (
						<VariableProductImage
							images={quote.productImages}
							selectedProductOptionValues={
								quote.productOptionValues
							}
							defaultImage={quote.product_image_file_url}
						/>
					)}
					{siblingQuote ? (
						<label>
							<CountIcon />+
							{quote.allSiblingQuotes
								? quote.allSiblingQuotes.length
								: '1'}{' '}
							Item
							{quote.allSiblingQuotes &&
							quote.allSiblingQuotes.length > 1
								? 's'
								: ''}
						</label>
					) : null}
				</div>
			</div>
			<div
				className={classNames({
					[collectionStyles.collectionItemFieldPair]: true,
					[styles.numberPair]: true,
				})}>
				<TextField
					readonly
					label="Quote Name"
					value={quote.quote_name}
				/>
				<TextField
					readonly
					label="Quote #"
					value={getQuoteDisplayNumber(quote).padStart(4, '0')}
				/>
			</div>
			<div
				className={classNames({
					[collectionStyles.collectionItemFieldPair]: true,
					[styles.productPair]: true,
				})}>
				<TextField
					readonly
					label="Product"
					value={
						quote.product_line_description
							? quote.product_line_description
							: quote.product_title
					}
				/>
				{!!quote.quote_modified_timestamp && (
					<TextField
						readonly
						label="Last Modified"
						value={date(quote.quote_modified_timestamp).format(
							'M/D/YY',
						)}
					/>
				)}
			</div>
			<div
				className={classNames({
					[collectionStyles.collectionItemFieldPair]: true,
					[styles.pricePair]: true,
				})}>
				{!demoModeActive &&
					(quote.product_line_id ? (
						<TextField
							readonly
							label="Quote Price"
							value={
								quote.quote_product_quantity > 1
									? quote.quote_product_quantity +
									  ' x ' +
									  formatCurrency(
											getQuoteTotalPrice(
												quote,
												siblingQuote,
											) / quote.quote_product_quantity,
									  )
									: formatCurrency(
											getQuoteTotalPrice(
												quote,
												siblingQuote,
											),
									  )
							}
						/>
					) : (
						<TextField
							readonly
							label="Quote Price"
							value={
								quote.quote_product_quantity > 1 &&
								!quote.allSiblingQuotes
									? quote.quote_product_quantity +
									  ' x ' +
									  formatCurrency(
											getQuoteTotalPrice(
												quote,
												siblingQuote,
											) / quote.quote_product_quantity,
									  )
									: formatCurrency(
											getQuoteTotalPrice(
												quote,
												siblingQuote,
											),
									  )
							}
						/>
					))}
				{(quote.user_id !== user.user_id || quote.original_quote) && (
					<TextField
						readonly
						label="Created By"
						value={
							quote.original_quote
								? quote.original_quote.user_display_name
								: quote.user_display_name
						}
					/>
				)}
				<TextField
					readonly
					label="Status"
					value={getQuoteStatusText(quote)}
				/>
			</div>
			<div className={collectionStyles.collectionItemActionsContainer}>
				<ArrowCircleIcon
					onClick={() => {
						dispatch(
							setConfiguratorOptions({
								quoteId: quote.quote_id,
								onClose: onEdit,
							}),
						);
					}}
				/>
			</div>
			<Menu actions={menuActions} className={styles.quoteMenu} />
		</div>
	);
};

export default QuotesListItem;
