import React, {useCallback, useEffect, useMemo} from 'react';
import { formatters } from '../formatters';
import classNames from 'classnames';
import FieldLabel from '../FieldLabel/FieldLabel';
import FieldValue from '../FieldValue/FieldValue';
import styles from './DesktopLayout.module.scss';
import {useSelector} from "react-redux";

const FieldRow = ({
	fields = [],
	schema,
	ranges = {},
	data = [],
	handleChange = () => {},
	handleRemoveRow = null,
	incomplete = [],
	invalid = [],
	highlightIncomplete = false,
	isEditable = true,
	isSizeZero = {},
}) => {
	const isCSRUser = useSelector((state) =>
		state.auth.user?.permissions?.includes('csr_access'),
	);
	const [highlight,setHighlight] = React.useState(highlightIncomplete);
	useEffect(() => {
		if(isEditable){
			setHighlight(true);
		}
	},[incomplete, isCSRUser, isEditable,highlightIncomplete]);

	const getFieldLabel = useCallback(
		(field) => {
			let label = schema[field.sizing_field_name]?.sizing_field_title;

			if (/.+\*.+$/.test(label)) {
				return label.replace(/\*.+$/, '');
			}

			return label;
		},
		[schema],
	);

	const onChange = (index, obj)=>{
		if(Object.keys(obj)[0] === 'edit_status'){
			handleChange(index, obj)
			return;
		}
		const field = fields.find(f => f.sizing_field_name === Object.keys(obj)[0]);
		if((field.sizing_field_type !== 'text' || field.sizing_field_name === 'name') && (field === undefined || field.lettering_max_chars === undefined || field.lettering_max_chars === null)){
			handleChange(index, obj)
			return;
		}
		let maxChars;
		if(field.lettering_max_chars) {
			maxChars = parseInt(field.lettering_max_chars);
		} else {
			maxChars = 20;
		}

		const value = Object.values(obj)[0];
		if(value.length > maxChars){
			obj[field.sizing_field_name] = value.substring(0, maxChars);
		}
		handleChange(index, obj)
	}

	const getIsEditable = useCallback((field_name,item,fieldType = '') => {
		
		if(item.edit_status && item.edit_status === 'locked' && (typeof field_name.includes !== 'function' || ( !field_name.includes('beg') && !field_name.includes('fit_type') && !field_name.includes('suspender') && fieldType !== 'text' && field_name !== 'name'))){
			return false;
		}
		if(item.edit_status && item.edit_status === 'locked' && field_name === 'name'){
			return false;
		}
		if (isEditable) {
			return true;
		}
		return isCSRUser && !['quantity_coat', 'quantity_pants', 'quantity_coveralls'].includes(field_name);
	}, [isCSRUser, isEditable]);

	const getIsLocked = (item, fieldName = '', fieldType = '') => {
		if(typeof fieldName.includes === 'function' && fieldName !== 'name'){
			if(fieldName.includes('beg') || fieldName.includes('fit_type') || fieldName.includes('suspender') || fieldType === 'text'){
				return false;
			}
		}
		return item.edit_status && item.edit_status === 'locked';
	};

	const getShowLock = (item)=>{
		return handleRemoveRow && item.edit_status;
	}
	return (
		<div className={classNames(styles.fieldGroupInputs)}>
			{fields.map((field, r) => (
				<div className={styles.tableFormField} key={r}>
					<FieldLabel label={getFieldLabel(field)} />
					{data.map((item, i) => (
						<FieldValue
							field={field}
							key={i}
							value={
								(item[field.sizing_field_name] === null
									? ''
									: item[field.sizing_field_name])
							}
							onChange={(value) =>{
								if (
									field.sizing_field_slug === 'name-patch'
									|| field.sizing_field_slug === 'firefighter-id'
									|| field.sizing_field_title === 'Rank'
								) {
									value = value.toUpperCase();
								}

								onChange(i, {
									[field.sizing_field_name]: value,
								})}
							}
							onRemove={
								r === 0 && typeof handleRemoveRow === 'function' && isEditable
									? () => handleRemoveRow(i)
									: null
							}
							showLock={getShowLock(item,i,handleRemoveRow)}
							isLocked={getIsLocked(item,field.sizing_field_name,field.sizing_field_type)}
							onLockToggle={() => {onChange(i, {edit_status: getIsLocked(item,i) ? 'unlocked' : 'locked'})}}
							formatter={formatters[field.sizing_field_type]}
							highlight={
								highlight &&
								(incomplete[i]?.includes(
									field.sizing_field_name,
								) ||
									invalid[i].includes(
										field.sizing_field_name,
									))
							}
							formData={item}
							tabIndex={Object.keys(schema).length * i}
							invalid={invalid[i].includes(
								field.sizing_field_name,
							)}
							recommendation={field.recommendations[i]?.recommendation_text}
							range={ranges[field.sizing_field_name]}
							isEditable={getIsEditable(field.sizing_field_name,item,field.sizing_field_type)}
							isSizeZero={isSizeZero}
							letteringMaxChar={field.lettering_max_chars ? field.lettering_max_chars : 20}
							disabled={field?.disabledIndexes && field?.disabledIndexes[i] === true}
							rowIndex={i}
						/>
					))}
				</div>
			))}
		</div>
	);
};

export default FieldRow;
