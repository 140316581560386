import classNames from "classnames";
import styles from "@components/OrderStatusCounts/OrderStatusCounts.module.scss";

const OrderStatusCounts = ({ statusCounts = {} }) => {
    return (
        <div className={styles.container}>
            <div
                className={classNames({
                    [styles.column]: true,
                })}>
                <span className={classNames({
                    [styles.label]: true,
                })}>Submitted:</span>

                <span className={classNames({
                    [styles.value]: true,
                })}>{statusCounts.submitted_count}</span>
            </div>

            <div
                className={classNames({
                    [styles.column]: true,
                })}>
                <span className={classNames({
                    [styles.label]: true,
                })}>Requires Edits:</span>

                <span className={classNames({
                    [styles.value]: true,
                })}>{statusCounts.requires_edits_count}</span>
            </div>

            <div
                className={classNames({
                    [styles.column]: true,
                })}>
                <span className={classNames({
                    [styles.label]: true,
                })}>Hold:</span>

                <span className={classNames({
                    [styles.value]: true,
                })}>{statusCounts.hold_count}</span>
            </div>
        </div>
    )
};

export default OrderStatusCounts;
