import {useEffect, useCallback, useState} from 'react';
import { formatCurrency } from '@utilities/currency';
import styles from './QuotePricing.module.scss';
import classNames from 'classnames';
import DiscountModal from '@components/DiscountModal/DiscountModal';
import * as net from "net";

const QuotePricing = ({
	price = 0,
	priceCoat = 0,
	pricePants = 0,
	netPrice = 0,
	netPriceCoat = 0,
	netPricePants = 0,
	discountPrice = null,
	discountAmount = null,
	label = 'Cost',
	handleDiscountChange = async () => {},
	className = null,
	discountModalClassName = null,
	isEditable = false,
	quantity = 1,
	quantityCoat = null,
	quantityPants = null,
	quantityCoveralls = null,
	hasSibling = false,
	quoteDiscount = 0,
	ignoreQuantity = false,
	splitPrices = null,
    isAccessory = false,
}) => {
	const [discountModalActive, setDiscountModalActive] = useState(false);
	const [discountQuantity, setDiscountQuantity] = useState(null);

	useEffect(() => {
		if(ignoreQuantity) {
			setDiscountQuantity(1);
			return;
		}

		if (quantityCoat > 0 && quantityPants === 0) {
			setDiscountQuantity(quantityCoat);
		} else if(quantityPants > 0 && quantityCoat === 0) {
			setDiscountQuantity(quantityPants);
		} else if(quantityCoveralls && quantityCoveralls > 0) {
			setDiscountQuantity(quantityCoveralls);
		} else if(!quantityCoat && !quantityPants && !quantityCoveralls && quantity) {
			setDiscountQuantity(quantity);
		} else {
			setDiscountQuantity(null);
		}

	}, [quantityCoat, quantityPants, quantityCoveralls, quantity, ignoreQuantity]);

	const accessoryPrices = useCallback((type = 'List') => {
		if(!splitPrices) {
			return null;
		}

		return splitPrices.map((splitPrice) => {
			return (
				<>
					<span className={type === 'List' ? styles.quotePricingQuantity : styles.discountedFinalPrice}>
						{type}: {splitPrice.quantity} x {formatCurrency(type === 'List' ? splitPrice.price : splitPrice.net)}
					</span>
					<br/>
				</>
			);
		});
	}, [splitPrices]);

	return (
		<div
			className={classNames({
				[styles.quotePricing]: true,
				[className]: !!className,
				[styles.quotePriceDiscounted]: !!discountAmount,
			})}>
			<div className={styles.quotePricingCost}>
				{
					(quantityCoat || quantityPants) ?
					(
						hasSibling
							?
							(
								<>
									<div>
										{quantityCoat > 0 && (
											<>
												<span className={styles.quotePricingQuantity}>
													Coat List: {quantityCoat} x
												</span>
												<span className={styles.quotePrice}>
													{formatCurrency(priceCoat / quantityCoat)}
												</span>
												<br/>
											</>
										)}
										{quantityPants > 0 && (
											<>
												<span className={styles.quotePricingQuantity}>
													Pant List: {quantityPants} x
												</span>
												<span className={styles.quotePrice}>
													{formatCurrency(pricePants / quantityPants)}
												</span>
												<br/>
											</>
										)}
										<span className={styles.quotePricingQuantity}>
											Total List:
										</span>
										<span className={styles.quotePrice}>
											{' ' + formatCurrency(price)}
										</span>
									</div>
									{
										!netPrice && !discountAmount ?
											<div>
												{quantityCoat > 0 && (
													<>
														<span className={styles.discountedFinalPrice}>
															Coat Net: {quantityCoat} x {formatCurrency(priceCoat / quantityCoat)}
														</span>
														<br/>
													</>
												)}
												{quantityPants > 0 && (
													<>
														<span className={styles.discountedFinalPrice}>
															Pant Net: {quantityPants} x {formatCurrency(pricePants / quantityPants)}
														</span>
														<br/>
													</>
												)}
												<span className={styles.discountedFinalPrice}>
													Total Net: {formatCurrency(price)}
												</span>
											</div>
											: null
									}
								</>
							)
							:
							quantityCoat > 0
								?
								(
									<>
										<div>
											<span className={styles.quotePricingQuantity}>
												Coat List: {quantityCoat} x
											</span>
											<span className={styles.quotePrice}>
											{quantityCoat > 1
												? formatCurrency(price / quantityCoat)
												: formatCurrency(price)}
											</span>
											<br/>
											<span className={styles.quotePricingQuantity}>
												Total List: {formatCurrency(price)}
											</span>
										</div>
										{
											!netPrice && !discountAmount ?
												<div>

													<span className={styles.discountedFinalPrice}>
														Coat Net: {quantityCoat} x
													</span>
													<span className={styles.quotePrice}>
														{quantityCoat > 1
															? formatCurrency(price / quantityCoat)
															: formatCurrency(price)}
													</span>
													<br/>
													<span className={styles.discountedFinalPrice}>
														Total Net List: {formatCurrency(price)}
													</span>
												</div>
												: null
										}
									</>
								)
								:
								(
									<>
										<div>
											<span className={styles.quotePricingQuantity}>
													Pant List: {quantityPants} x
											</span>
											<span className={styles.quotePrice}>
												{quantityPants > 1
													? formatCurrency(price / quantityPants)
													: formatCurrency(price)}
											</span>
											<br/>
											<span className={styles.quotePricingQuantity}>
												Total List: {formatCurrency(price)}
											</span>
										</div>

										{
											!netPrice && !discountAmount ?
												<div>

													<span className={styles.discountedFinalPrice}>
														Pant Net: {quantityPants} x
													</span>
													<span className={styles.quotePrice}>
														{quantityPants > 1
															? formatCurrency(price / quantityPants)
															: formatCurrency(price)}
													</span>
													<br/>
													<span className={styles.discountedFinalPrice}>
														Total Net List: {formatCurrency(price)}
													</span>
												</div>
												: null
										}
									</>
								)
					)
					: null

				}

				{!quantityCoat && !quantityPants && !quantityCoveralls && isAccessory && (
					quantity > 1
					?
						(
							<>
								<div>
	                                {accessoryPrices('List')}
									<span className={styles.quotePricingQuantity}>
											Total List: {formatCurrency(price)}
									</span>
								</div>
									{!!discountAmount &&
										(
											<div>
												{splitPrices?.map(() => {
													return (
														<>
															<span className={styles.discountedFinalPrice}>
																Discount: {discountAmount}%
															</span>
															<br/>
														</>
													)
												})}
												<span className={styles.discountedFinalPrice}>
													Discount: {discountAmount}%
												</span>
											</div>
										)
									}
								<div>
									{accessoryPrices('Net')}
									<span className={styles.discountedFinalPrice}>
											Total Net: {formatCurrency(discountPrice ?? price)}
									</span>
								</div>
							</>
						)
					:
						(
							<>
								<div>
									<span className={styles.quotePrice}>
										List: {formatCurrency(price)}
									</span>
									<br/>
									<span className={styles.quotePricingQuantity}>
											Total List: {formatCurrency(price)}
									</span>
								</div>
								{!!discountAmount &&
									(
										<div>
											{splitPrices?.map(() => {
												return (
													<>
															<span className={styles.discountedFinalPrice}>
																Discount: {discountAmount}%
															</span>
														<br/>
													</>
												)
											})}
											<span className={styles.discountedFinalPrice}>
													Discount: {discountAmount}%
												</span>
										</div>
									)
								}
								<div>
									<span className={styles.discountedFinalPrice}>
										Net: {formatCurrency(discountPrice ?? price)}
									</span>
									<br/>
									<span className={styles.discountedFinalPrice}>
											Total Net: {formatCurrency(discountPrice ?? price)}
									</span>
								</div>
							</>
						)
				)}

				{quantityCoveralls !== null &&
						quantityCoveralls > 0 && (
							<>
								<div>
									<span className={styles.quotePricingQuantity}>
											Coverall List: {quantityCoveralls} x {formatCurrency(price / quantityCoveralls)}
									</span>
									<br />
									<span className={styles.quotePricingQuantity}>
											Total List: {quantityCoveralls} x {formatCurrency(price)}
									</span>
								</div>
							</>
						)
				}

				{!!discountAmount && !isAccessory && (
					<>
						{
							discountQuantity
							?
								<>
									<div>
										<span className={styles.discountedFinalPrice}>
											Discount: {discountAmount}%
										</span>
										<br/>
										<span className={styles.discountedFinalPrice}>
											Discount: {discountAmount}%
										</span>
									</div>
									<div>
										<span className={styles.discountedFinalPrice}>
											{quantityCoveralls > 0 ? 'Coverall ' : ''}Net: {discountQuantity > 0 ? discountQuantity + ' x ' : ''}{formatCurrency(discountPrice / discountQuantity)}
										</span>
										<br/>
										<span className={styles.discountedFinalPrice}>
											Total Net: {formatCurrency(discountPrice)}
										</span>
									</div>
								</>
							:
								(quantityCoat || quantityPants) &&
								(
									hasSibling
										?
										(
											<>
												<div>

													{quantityCoat > 0 && (
														<>
															<span className={styles.discountedFinalPrice}>
																Discount: {discountAmount}%
															</span>
															<br/>
														</>
													)}

													{quantityPants > 0 && (
														<>
															<span className={styles.discountedFinalPrice}>
																Discount: {discountAmount}%
															</span>
															<br/>
														</>
													)}
													<span className={styles.discountedFinalPrice}>
														Discount: {discountAmount}%
													</span>
													<br/>
												</div>
												<div>
													{quantityCoat > 0 && (
														<>
															<span className={styles.discountedFinalPrice}>
																Coat Net: {quantityCoat > 1 ? quantityCoat + ' x ' : ''}{formatCurrency((priceCoat - (priceCoat * quoteDiscount)) / quantityCoat)}
															</span>
															<br/>
														</>
													)}
													{quantityPants > 0 && (
														<>
															<span className={styles.discountedFinalPrice}>
																Pant Net: {quantityPants > 1 ? quantityPants + ' x ' : ''}{formatCurrency((pricePants - (pricePants * quoteDiscount)) / quantityPants)}
															</span>
														</>
													)}
													<br/>
													<span className={styles.discountedFinalPrice}>
														Total Net: {formatCurrency(discountPrice)}
													</span>
												</div>
											</>
										)
										:
										quantityCoat > 0
											?
											(
												<>
													<div>
														<span className={styles.discountedFinalPrice}>
															Discount: {discountAmount}%
														</span>
														<br/>
														<span className={styles.discountedFinalPrice}>
															Discount: {discountAmount}%
														</span>
													</div>
													<div>
														<span className={styles.discountedFinalPrice}>
															Coat Net: {formatCurrency(discountPrice / quantityCoat)}
														</span>
														<br/>
														<span className={styles.discountedFinalPrice}>
															Total Net: {formatCurrency(discountPrice)}
														</span>
													</div>
												</>
											)
											:
											(
												<>
													<div>
														<span className={styles.discountedFinalPrice}>
															Discount: {discountAmount}%
														</span>
														<br/>
														<span className={styles.discountedFinalPrice}>
															Discount: {discountAmount}%
														</span>
													</div>
													<div>
														<span className={styles.discountedFinalPrice}>
															Pant Net: {formatCurrency(discountPrice / quantityPants)}
														</span>
														<br/>
														<span className={styles.discountedFinalPrice}>
															Total Net: {formatCurrency(discountPrice)}
														</span>
													</div>
												</>
											)
								)
						}
					</>
				)}

				{
					(netPrice || netPriceCoat || netPricePants) ? (
						<>
							{
								(quantityCoat || quantityPants) ?
								(
									hasSibling
										?
										(
											<div>
												{quantityCoat > 0 && (
													<>
														<span className={styles.discountedFinalPrice}>
															Fire-Dex Approved Coat Net: {quantityCoat} x
														</span>
														<span className={styles.quotePrice}>
															{' ' + formatCurrency(netPriceCoat / quantityCoat)}
														</span>
														<br/>
													</>
												)}
												{quantityPants > 0 && (
													<>
														<span className={styles.discountedFinalPrice}>
															Fire-Dex Approved Pant Net: {quantityPants} x
														</span>
														<span className={styles.quotePrice}>
															{' ' + formatCurrency(netPricePants / quantityPants)}
														</span>
														<br/>
													</>
												)}
												<span className={styles.discountedFinalPrice}>
													Fire-Dex Approved Total Net:
												</span>
												<span className={styles.quotePrice}>
													{' ' + formatCurrency(netPrice)}
												</span>
											</div>
										)
										:
										quantityCoat > 0
											?
											(
												<>
													<div>
														<span className={styles.discountedFinalPrice}>
															Fire-Dex Approved Coat Net: {quantityCoat} x
														</span>
														<span className={styles.quotePrice}>
															{' '}{quantityCoat > 1
																? formatCurrency(netPrice / quantityCoat)
																: formatCurrency(netPrice)}
														</span>
														<br/>
														<span className={styles.discountedFinalPrice}>
															Fire-Dex Approved Total Net: {formatCurrency(netPrice)}
														</span>
													</div>
												</>
											)
											:
											(
												<>
													<div>
														<span className={styles.discountedFinalPrice}>
																Fire-Dex Approved Pant Net: {quantityPants} x
														</span>
														<span className={styles.quotePrice}>
															{quantityPants > 1
																? formatCurrency(netPrice / quantityPants)
																: formatCurrency(netPrice)}
														</span>
														<br/>
														<span className={styles.discountedFinalPrice}>
															Fire-Dex Approved Total Net: {formatCurrency(netPrice)}
														</span>
													</div>
													</>
											)
								) : null

							}

							{!quantityCoat && !quantityPants && !quantityCoveralls && (
								quantity > 1
									?
									(
										<>
											<div>
												<span className={styles.discountedFinalPrice}>
														Fire-Dex Approved Net: {quantity} x
												</span>
															<span className={styles.quotePrice}>
													{' ' + formatCurrency(netPrice / quantity)}
												</span>
												<br/>
												<span className={styles.discountedFinalPrice}>
														Total Fire-Dex Approved Net: {formatCurrency(netPrice)}
												</span>
											</div>
										</>
									)
									:
									(
										<div>
											<span className={styles.discountedFinalPrice}>
												Fire-Dex Approved Net: {formatCurrency(netPrice)}
											</span>
											<br/>
											<span className={styles.discountedFinalPrice}>
													Total Fire-Dex Approved Net: {formatCurrency(netPrice)}
											</span>
										</div>
									)
							)}

							{quantityCoveralls !== null && (
								quantityCoveralls > 1
									?
									(
										<>
											<div>
												<span className={styles.discountedFinalPrice}>
														Fire-Dex Approved Coverall Net: {quantityCoveralls} x
												</span>
												<span className={styles.quotePrice}>
													{' ' + formatCurrency(netPrice / quantityCoveralls)}
												</span>
												<br />
												<span className={styles.discountedFinalPrice}>
													Total Fire-Dex Approved Coverall Net: {formatCurrency(netPrice)}
												</span>
											</div>
										</>
									)
									:
									(
										<div>
											<span className={styles.discountedFinalPrice}>
												Fire-Dex Approved Coverall Net: {formatCurrency(netPrice)}
											</span>
											<br />
											<span className={styles.discountedFinalPrice}>
												Total Fire-Dex Approved Coverall Net: {formatCurrency(netPrice)}
											</span>
										</div>
									)
							)}
						</>
					)
						: null
				}
			</div>
			<div className={styles.quotePricingDiscount}>
				{isEditable && !discountAmount && (
					<button
						className={styles.enterDiscountButton}
						onClick={() => setDiscountModalActive(true)}>
						Enter Discount
					</button>
				)}
				{!!discountAmount && (
					<>
						<span>{discountAmount}% Discount Applied</span>
						{isEditable && (
							<button
								className={styles.editDiscountButton}
								onClick={() => setDiscountModalActive(true)}>
								Edit
							</button>
						)}
					</>
				)}
			</div>
			<DiscountModal
				active={discountModalActive}
				price={price}
				discount={discountAmount}
				onClose={() => setDiscountModalActive(false)}
				onSubmit={handleDiscountChange}
				className={classNames({
					[discountModalClassName]: !!discountModalClassName,
				})}
			/>
		</div>
	);
};

export default QuotePricing;
