import { useEffect, useState } from 'react';
import classNames from 'classnames';
import IOActions from '../IOActions/IOActions';
import MobileForm from './Form';
import ChevronIcon from '@components/Icons/ChevronIcon';
import TrashIcon from '@components/Icons/TrashIcon';
import styles from './MobileLayout.module.scss';

const MobileLayout = ({
	quoteId,
	copy = null,
	data = [],
	schema = {},
	ranges = {},
	highlightIncomplete = false,
	incomplete = [],
	invalid = [],
	handleChange = () => {},
	handleRemoveRow = () => {},
	handleClose = () => {},
	handleUpload = () => {},
	isEditable = true,
	isSizeZero = {},
	refresh = () => {},
	allowVirtualSizer = false,
}) => {
	/**
	 * Keep track of expanded/collapsed sections
	 */

	const [collapsedSections, setCollapsedSections] = useState(
		new Array(data.length).fill(true),
	);

	useEffect(() => {
		setCollapsedSections((sections) =>
			data.map((_, i) =>
				sections[i] !== undefined ? sections[i] : true,
			),
		);
	}, [data]);

	return (
		<div className={styles.container}>
			{copy}
			<IOActions
				quoteId={quoteId}
				handleClose={handleClose}
				handleUpload={handleUpload}
				isEditable={isEditable}
				refresh={refresh}
				allowVirtualSizer={allowVirtualSizer}
			/>
			<div className={styles.list}>
				{data.map((item, i) => (
					<div
						className={classNames({
							[styles.listItem]: true,
							[styles.listItemHighlight]:
								highlightIncomplete &&
								(incomplete[i]?.length || invalid[i].length),
						})}
						key={i}
						onClick={() => {
							setCollapsedSections((sections) => {
								return data.map((_, index) =>
									index === i
										? !sections[index]
										: sections[index],
								);
							});
						}}>
						<header className={styles.listItemHeader}>
							<span>{item.name || <i>Firefighter Name</i>}</span>
							{isEditable && (
								<button
									className={styles.removeButton}
									onClick={(e) => {
										e.stopPropagation();
										handleRemoveRow(i);
									}}>
									<TrashIcon
										className={styles.removeButtonIcon}
									/>
								</button>
							)}
							<ChevronIcon
								className={classNames({
									[styles.listItemHeaderIndicator]: true,
									[styles.flipped]: collapsedSections[i],
								})}
							/>
						</header>
						<MobileForm
							index={i}
							schema={schema}
							ranges={ranges}
							data={item}
							collapsed={collapsedSections[i]}
							handleChange={(data) => handleChange(i, data)}
							incomplete={incomplete}
							invalid={invalid}
							highlightIncomplete={highlightIncomplete}
							isEditable={isEditable}
							isSizeZero={isSizeZero}
						/>
					</div>
				))}
			</div>
		</div>
	);
};

export default MobileLayout;
