import React, { useCallback, useEffect, useState } from 'react';
import modalStyles from '@components/Modal/Modal.module.scss';
import Button from '@components/Button/Button';
import styles from './ErrorMessage.module.scss'
import classNames from 'classnames';
import {useDispatch, useSelector} from "react-redux";
import { resetError, setError } from '@store/reducers/error';
import {useErrorAlert} from "@hooks/useErrorAlert";
import ErrorModal from "@components/ErrorModal/ErrorModal";


const ErrorMessage = () => {
	const dispatch = useDispatch();

	const error = useSelector(
		(state) => state.error.error,
	);
	const errorTitle = useSelector(
		(state) => state.error.errorInformation.errorTitle,
	);
	const errorMessage = useSelector(
		(state) => state.error.errorInformation.errorMessage,
	);
	const errorType = useSelector(
		(state) => state.error.errorInformation.errorType,
	);

	const errorAction = useSelector(
		(state) => state.error.errorInformation.errorAction,
	);

	const errorIgnoreAction = useSelector(
		(state) => state.error.errorInformation.errorIgnoreAction,
	);

	const [, setErrorAlert] = useErrorAlert();

	window.addEventListener('local-error', () => {
		setErrorAlert({errorTitle: 'Error', errorMessage: JSON.parse(localStorage.getItem('error'))});
	});


	/**
	 * Handle Modal Close
	 */

	const handleClose = useCallback(() => {
		if(errorIgnoreAction) {
			errorIgnoreAction();
		}
		localStorage.removeItem('error');
		dispatch(resetError());
	}, [dispatch, errorIgnoreAction]);

	const handleConfirm = useCallback(() => {
		if (errorAction) {
			errorAction();
		}
		localStorage.removeItem('error');
		dispatch(resetError());
	}, [dispatch, errorAction]);

	return (
		<ErrorModal
			active={error}
			onClose={() => handleClose()}
			>
			<h4 className={modalStyles.modalTitle}>{errorTitle}</h4>
			<p className={styles.errorMessageBody} dangerouslySetInnerHTML={{__html:errorMessage}}></p>
			<div className={modalStyles.modalActions}>
				{(!errorType || errorType !== 'confirm') && (
					<Button variant="solid" onClick={() => handleClose()}>
						Close
					</Button>
				)}
				{errorType && errorType === 'confirm' && (
					<>
						<Button variant="plain" onClick={() => handleClose()}>
							Close
						</Button>
						<Button variant="solid" onClick={() => handleConfirm()}>
							Confirm
						</Button>
					</>
				)}
			</div>
		</ErrorModal>
	);
};

export default ErrorMessage;
