import { useState, useMemo, useCallback } from 'react';
import Card from '@components/Card/Card';
import Resource from './components/Resource/Resource';
import styles from './ResourceLines.module.scss';
import SelectField from '@components/SelectField/SelectField';
import classNames from 'classnames';
import filterStyles from '@components/Collection/CollectionFilters.module.scss';
import GridIcon from '@components/Icons/GridIcon';
import ListIcon from '@components/Icons/ListIcon';
import CheckIcon from '@components/Icons/CheckIcon';
import ActionButton from './components/ActionButton/ActionButton';
import CloseIcon from '@components/Icons/CloseIcon';
import ShareIcon from '@components/Icons/Share1Icon';
import HeartIcon from '@components/Icons/HeartIcon';
import DownloadIcon from '@components/Icons/DownloadIcon';
import ShareResourceModal from '@components/ShareResourceModal/ShareResourceModal';
import ConfirmationModal from "@components/ConfirmationModal/ConfirmationModal";


const ResourceLines = ({
						   onSelectDownload,
						   setSelectedProductIndex,
						   setSelectedFolder,
						   selectedFolder,
						   selectedResourceIndexes,
						   toggleFavorite,
						   productDownloadsActive,
						   onSelectAll,
						   onDeselectAll,
						   favoriteSelected,
						   generateProductDownloadArchive,
						   productDownloadShareKeys,
						   setProductDownloadShareKeys,
						   setGetGeneral,
						   getGeneral,
						   toggleFolderFavorite,
						   resources = [],
						   folders = []
}) => {
	const [viewMode, setViewMode] = useState('List View');
	const [shareResourceModal, setShareResourceModal] = useState(false);
	const [generateArchiveConfirmationActive, setGenerateArchiveConfirmationActive] = useState(false);

	const handleViewModeChange = (e) => {
		setViewMode(e.target.value);
	}

	const initializeShareModal = useCallback((productDownloadIds) => {
		setProductDownloadShareKeys(productDownloadIds);
		setShareResourceModal(true);
	}, [setProductDownloadShareKeys]);

	const buttons = useMemo(() => {
		return [
			{
				background: 'white',
				color: '#222222',
				icon: <CheckIcon/>,
				label: 'Select All',
				handleClick: () => {
					onSelectAll();
				},
				disabled: false
			},
			{
				background: 'white',
				color: '#222222',
				icon: <CloseIcon/>,
				label: 'Select None',
				handleClick: () => {
					onDeselectAll();
				},
				disabled: false
			},
			{
				background: '#9F9E9E',
				color: 'white',
				icon: <ShareIcon/>,
				label: 'Share',
				handleClick: () => {
					initializeShareModal(selectedResourceIndexes.map(resourceIndex => {
						return resources[resourceIndex].product_download_id;
					}));
				},
				disabled: selectedResourceIndexes.length === 0
			},
			{
				background: '#E87124',
				color: 'white',
				icon: <HeartIcon/>,
				label: 'Favorite',
				handleClick: () => {
					favoriteSelected();
				},
				disabled: selectedResourceIndexes.length === 0
			},
			{
				background: '#222222',
				color: 'white',
				icon: <DownloadIcon stroke="#222222"/>,
				label: 'Download',
				handleClick: () => {
					setGenerateArchiveConfirmationActive(true);
				},
				disabled: selectedResourceIndexes.length === 0
			}
		]
	}, [onSelectAll, onDeselectAll, initializeShareModal, selectedResourceIndexes, resources, favoriteSelected])

	const resourcesOutput = useMemo(() => {
		if(resources.length > 0) {
			const type = resources[0].product_download_id ? 'file' : (resources[0].product_line_id ? 'productLine' : 'product');
			if(type === 'file') {
				let folderOutput;
				if(selectedFolder) {
					folderOutput = (<></>);
				} else {
					folderOutput = folders.map(folder => {
						return (
							<li key={folder.product_product_line_product_download_folder}>
								<Resource
									resource={folder}
									viewMode={viewMode}
									type={'folder'}
									onSelect={() => {
										setSelectedFolder(folder.product_product_line_product_download_folder);
									}}
									toggleFavorite={() => {
										toggleFolderFavorite({
											productLineId: folder.product_line_id,
											productId: folder.product_id,
											folder: folder.product_product_line_product_download_folder
										})
									}}
									isCheck={false}
									initializeShareModal={() => {
										console.log('share folder!')
									}}
									generateProductDownloadArchive={generateProductDownloadArchive}
								/>
							</li>
						);
					})
				}

				return (
					<>
					{folderOutput}
						{
							resources
								.map((resource, index) => {
									const type = resource.product_download_id ? 'file' : (resource.product_line_id ? 'productLine' : 'product');
									let key;
									switch (type) {
										case 'file':
											key = resource.product_download_id;
											break;
										case 'productLine':
											key = `productLine-${resource.product_line_id}`;
											break;
										case 'product':
											key = `product-${resource.product_id}`;
											break;
									}
									return (
										<li key={key}>
											<Resource
												resource={resource}
												viewMode={viewMode}
												type={type}
												onSelect={() => {
													if (type === 'file') {
														onSelectDownload(index);
													} else {
														setSelectedProductIndex(index);
													}
												}}
												toggleFavorite={() => {
													toggleFavorite(index);
												}}
												isCheck={selectedResourceIndexes.includes(index)}
												initializeShareModal={initializeShareModal}
												generateProductDownloadArchive={generateProductDownloadArchive}
											/>
										</li>
									);
								})
						}
					</>
				)
			}
		}

		return resources.map((resource, index) => {
			const type = resource.product_download_id ? 'file' : (resource.product_line_id ? 'productLine' : 'product');
			let key;
			switch (type) {
				case 'file':
					key = resource.product_download_id;
					break;
				case 'productLine':
					key = `productLine-${resource.product_line_id}`;
					break;
				case 'product':
					key = `product-${resource.product_id}`;
					break;
			}
			return (
				<li key={key}>
					<Resource
						resource={resource}
						viewMode={viewMode}
						type={type}
						onSelect={() => {
							if(type === 'file') {
								onSelectDownload(index);
							} else {
								setSelectedProductIndex(index);
							}
						}}
						toggleFavorite={() => {
							toggleFavorite(index);
						}}
						isCheck={selectedResourceIndexes.includes(index)}
						initializeShareModal={initializeShareModal}
						generateProductDownloadArchive={generateProductDownloadArchive}
					/>
				</li>
			);
		});
	}, [folders, generateProductDownloadArchive, initializeShareModal, onSelectDownload, resources, selectedFolder, selectedResourceIndexes, setSelectedFolder, setSelectedProductIndex, toggleFavorite, toggleFolderFavorite, viewMode]);

	return (
		<>
			<div className={styles.viewContainer}>
				<SelectField
					className={classNames({
						[filterStyles.filter]: true,
						[styles.viewSelect]: true,
					})}
					inputClassName={filterStyles.filterInput}
					menuClassName={filterStyles.filterInputMenu}
					name="filter"
					value={viewMode}
					onChange={handleViewModeChange}
					options={['List View', 'Grid View'].map((orderStatus) => ({
						label: orderStatus,
						value: orderStatus,
					}))}
					preIcon={viewMode == 'Grid View' ? <GridIcon width="16" height="16" /> : <ListIcon stroke="#222222" width="16" height="16" />}
					allowNull={false}
					theme="white"
				/>
				{productDownloadsActive ? <div className={styles.actionButtons}>
					{buttons.map((button, key) => {
						return <ActionButton key={key} info={button} handleClick={button.handleClick} disabled={button.disabled} />
					})}
				</div> : null}
			</div>
			<Card
				title={''}
				cardId={''}>
				<ul className={viewMode == 'Grid View' ? styles.list : ''}>
					{
						!productDownloadsActive ?
							<li>
								<Resource
									type="productLine"
									resource={{
										product_line_description: 'General / Misc.',
										product_line_is_favorite: '0',
										isGeneral: true
									}}
									viewMode={viewMode}
									onSelect={() => {
										setSelectedProductIndex(null);
										setGetGeneral(true);
									}}
									generateProductDownloadArchive={generateProductDownloadArchive}
									showFavoriteToggle={false}
								/>
							</li>
							: null
					}
					{resourcesOutput}
				</ul>
			</Card>

			<ShareResourceModal
				active={shareResourceModal}
				onClose={() => {
					setShareResourceModal(false)
					setProductDownloadShareKeys();
				}}
				selectedProductDownloadIds={productDownloadShareKeys}
			/>
			<ConfirmationModal
				title="Warning"
				message="Are you sure you want to download all selected assets?"
				active={generateArchiveConfirmationActive}
				onConfirm={() => {
					generateProductDownloadArchive({productId: null, productLineId: null});
					setGenerateArchiveConfirmationActive(false);
				}}
				onClose={() => {
					setGenerateArchiveConfirmationActive(false);
				}}
			/>
		</>
	);
};

export default ResourceLines;
