import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Helmet } from 'react-helmet';
import Screen from '@components/Screen/Screen';
import WelcomeHeader from '@components/WelcomeHeader/WelcomeHeader';
import ResourceHeader from '@components/ResourceHeader/ResourceHeader';
import styles from './Resources.module.scss';
import ChevronIcon from '@components/Icons/ChevronIcon';
import SearchIcon from '@components/Icons/SearchIcon';
import TextField from '@components/TextField/TextField';
import SelectField from '@components/SelectField/SelectField';
import filterStyles from '@components/Collection/CollectionFilters.module.scss';
import classNames from 'classnames';
import { useSelector } from 'react-redux';
import ButtonField from '@components/ButtonField/ButtonField';
import Button from '@components/Button/Button';
import Http from '@utilities/Http';
import { API_ROOT } from '@config';

const Prices = ({ location }) => {
	const [isFetching, setIsFetching] = useState(false);
	const user = useSelector((state) => state.auth.user);
	const [productLines, setProductLines] = useState([]);
	const [getProductLines, setGetProductLines] = useState([]);
	const [getProducts, setGetProducts] = useState([]);
	const [values, setValues] = useState({ 'search':'', 'tcm_ids':'', 'product_line_id':'', 'product':'' });

	const fetchProductLines = useCallback(async () => {
		const endpoint = user?.organization_id
			? `organizations/product-lines/${user.organization_id}`
			: 'product-lines/all';

		const { data } = await Http().get(`${API_ROOT}/${endpoint}`);

		setProductLines([{product_line_id:null,product_line_description:'All Product Lines'},{product_line_description: 'Accessories',product_line_id: -1,},...data.productLines.filter((productLine) => !!productLine.product_line_id)]);
	}, [user]);

	const fetchProducts = useCallback(async () => {

		const { data } = await Http().get(`${API_ROOT}/products`);
		let products = [{product_id: null, product_title: 'All Products'}];
		data.products.forEach((product) => {
			if (product.products.length > 0){
				products = [...products, ...product.products.filter((prod)=> !products.some((p) => p.product_id === prod.product_id))];
			}
		});
		setGetProducts(products);
	},[]);

	useEffect(() => {
		fetchProductLines();
	}, [fetchProductLines])
	useEffect(() => {
		fetchProducts();
	}, [fetchProducts])

	useEffect(() => {
		if (productLines.length) {
			setGetProductLines(
				productLines?.sort((a, b) =>
					a.product_line_description.toLowerCase() >
					b.product_line_description.toLowerCase()
						? 1
						: -1,
				),
			);
		}
	}, [productLines]);

	const handleChange = useCallback(
		(e) => {
			setValues((values) => ({
				...values,
				[e.target.name]: e.target.value,
			}));
		},
		[setValues],
	);

	const downloadPrices = useCallback(() => {
		if(isFetching) return;
		setIsFetching(true);
		Http()
			.get(`${API_ROOT}/prices`, {
				params: {
					...values,
				},
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute('download', 'prices.csv');
				document.body.appendChild(link);
				link.click();
			})
			.catch((error) => {
				console.error(error);
			}).finally(() => {
				setIsFetching(false);
			});

	},[values]);

	return (
		<Screen
			hideSidebar={!user}
			headerContent={
				<>
					{!!user && <WelcomeHeader showQuote={false} />}
					<div className={filterStyles.filtersContainer}>
						<div className={filterStyles.filtersContent}>
							<div
								className={classNames({
									[filterStyles.fieldsList]: true,
									[styles.fieldsList]: true,
								})}>
								<TextField
									className={classNames({
										[filterStyles.filter]: true,
										[styles.nameFilter]: true,
									})}
									inputClassName={filterStyles.filterInput}
									name="search"
									value={values.search}
									onChange={handleChange}
									placeholder="Search Prices"
									adornment={<SearchIcon fill="#FFF" />}
								/>
								<TextField
									className={classNames({
										[filterStyles.filter]: true,
										[styles.nameFilter]: true,
									})}
									inputClassName={filterStyles.filterInput}
									name="tcm_ids"
									value={values.tcm_ids}
									onChange={handleChange}
									placeholder="TCM IDs (comma separated)"
									adornment={<SearchIcon fill="#FFF" />}
								/>
								<SelectField
									className={classNames({
										[filterStyles.filter]: true,
										[styles.productFilter]: true,
									})}
									inputClassName={filterStyles.filterInput}
									menuClassName={filterStyles.filterInputMenu}
									name="product_line_id"
									value={values.product_line_id}
									onChange={handleChange}
									placeholder="Product Line"
									options={[
										...getProductLines,
									]}
									labelField="product_line_description"
									valueField="product_line_id"
									indicator={<ChevronIcon fill="rgba(255,255,255,0.5)" />}
									allowNull={false}
								/>
								<SelectField
									className={classNames({
										[filterStyles.filter]: true,
										[styles.productFilter]: true,
									})}
									inputClassName={filterStyles.filterInput}
									menuClassName={filterStyles.filterInputMenu}
									name="product"
									value={values.product}
									onChange={handleChange}
									placeholder="Product"
									options={getProducts}
									labelField="product_title"
									valueField="product_id"
									indicator={<ChevronIcon fill="rgba(255,255,255,0.5)" />}
									allowNull={false}
								/>
								<Button
									loading={isFetching}
									className={classNames({
										[filterStyles.filter]: true,
										[styles.filterButton]: true,
									})}
									onClick={() => {
										downloadPrices();
									}}>
									Download
								</Button>
							</div>
						</div>
					</div>
				</>
			}>
			<Helmet>
				<title>Prices Download</title>
			</Helmet>
		</Screen>
	);
};
export default Prices;